import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/containers/layout";
import Heading from "../components/typography/heading";
import Container from "../components/containers/container";
import Section from "../components/containers/section";
import SEO from "../components/globals/seo";
import HTMLRenderer from "../utils/htmlRenderer";

const PrivacyPolicyPage = ({ data }) => {
  const { title, content } = data.prismic.data;

  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="The Privacy Policy for TopTekkers"
        path="/privacy"
      />
      
      <Section>
        <Container>
          <Heading>{title.text}</Heading>
          <HTMLRenderer html={content.html} />
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
{
  prismic: prismicPrivacyPolicy {
    data {
      title {
        text
      }
      content {
        html
      }
    }
  }
}
`;

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withTheme(PrivacyPolicyPage);
